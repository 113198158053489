import React, { Component } from "react";
import {
  translate
} from 'react-switch-lang';
import PropTypes from 'prop-types';

const idividualDailyMilkAverage = 35;

class Calculator extends Component {
  state = { milkPrice: 1, 
    items: {
    grade1: 0,
    grade2: 0,
    grade3: 0,
    grade4: 0,
    grade5: 0
  }
 };

  componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.dataItemsFromParent });
  }

  componentDidMount() {
    this.setState({ items: this.props.dataItemsFromParent });
  }

  render() {
    const {t} = this.props;
    const grade1Cows =
      this.state.items.grade1 !== undefined
        ? this.state.items.grade1
        : 0;
    const grade2Cows =
      this.state.items.grade2 !== undefined
        ? this.state.items.grade2
        : 0;
    const grade3Cows =
      this.state.items.grade3 !== undefined
        ? this.state.items.grade3
        : 0;
    const grade4Cows =
      this.state.items.grade4 !== undefined
        ? this.state.items.grade4
        : 0;
    const grade5Cows =
      this.state.items.grade5 !== undefined
        ? this.state.items.grade5
        : 0;

    const totalCows =
      grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows;

    const block1 =
      (grade1Cows /
        (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
      1;

    const block2 =
      (grade2Cows /
        (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
      1;

    const block3 =
      (grade3Cows /
        (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
      0.949;

    const block4 =
      (grade4Cows /
        (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
      0.832;

    const block5 =
      (grade5Cows /
        (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
      0.64;

    const individualMilkLostAverageResult =
      idividualDailyMilkAverage / (block1 + block2 + block3 + block4 + block5) -
      idividualDailyMilkAverage;

    const individualMilkLostAverage =
      Number.isNaN(individualMilkLostAverageResult) ||
      individualMilkLostAverageResult < 0
        ? 0
        : individualMilkLostAverageResult;

    const totalDailyMilkLostAverage =
      totalCows * individualMilkLostAverage * this.state.milkPrice;

    return (
      <div>
        <form>
          <label for="price-milk" className="form-label">
            {t('dashboard.calculator.milkPriceTitle')}
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input
              value={this.state.milkPrice}
              id="milkPrice"
              onChange={(event) => {
                const price = event.target.value;
                if (!price || price.match(/^\d{1,}(\.\d{0,4})?$/)) {
                  this.setState({ milkPrice: price });
                }
              }}
              type="number"
              className="form-control"
              aria-label="Precio por litro de leche:"
              placeholder="0.0"
            />
          </div>
        </form>
        <p>
        {t('dashboard.calculator.milkAverageTitle')}
          {idividualDailyMilkAverage}
           {t('dashboard.calculator.liters')}
        </p>
        <p>
        {t('dashboard.calculator.milkLossTitle')}
          <strong> ${totalDailyMilkLostAverage.toFixed(2)}</strong>
        </p>
      </div>
    );
  }
}

Calculator.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Calculator);