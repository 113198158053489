import React, { Component } from "react";
import Chart from "chart.js/auto";

var myChart = null;
class GradeSummaryChart extends Component {
  chartRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.dataItemsFromParent });
  }

  componentDidMount() {
    this.setState({ data: this.props.dataFromParent });
  }

  componentDidUpdate() {
    const ctx = this.chartRef.current.getContext("2d");

    const grade1 = this.props.dataFromParent.grade1;
    const grade2 = this.props.dataFromParent.grade2;
    const grade3 = this.props.dataFromParent.grade3;
    const grade4 = this.props.dataFromParent.grade4;
    const grade5 = this.props.dataFromParent.grade5;

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5"],
        datasets: [
          {
            data: [
              grade1,
              grade2,
              grade3,
              grade4,
              grade5,
            ],
            borderColor: [
              "#00B3B7",
              "#BFAC36",
              "#DC8A51",
              "#6F9E31",
              "#8C3424",
            ],
            backgroundColor: [
              "#00B3B7",
              "#BFAC36",
              "#DC8A51",
              "#6F9E31",
              "#8C3424",
            ],
          },
        ],
      },

      options: {
        responsive: true,
        cutout: 100,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
    });
  }
  render() {
    return (
        <canvas ref={this.chartRef} />
    );
  }
}

export default GradeSummaryChart;
