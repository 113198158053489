import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  translate,
} from 'react-switch-lang';
import { ReactComponent as IconLinkedinSVG } from "../img/linkedin.svg";


class FooterComponent  extends Component {
    form = React.createRef();
    render() {
    const {t} = this.props;
    return (
      <footer>
        <div className="container py-1">
          <div className="row justify-content-center justify-content-lg-between align-items-center">
            <div className="col-12 col-lg-6">
              <p className="text-white m-0 text-center text-lg-start">Cow IA</p>
            </div>
            <div className="col-12 col-lg-6 pe-0 text-end">
              <nav>
                <ul className="nav justify-content-center justify-content-lg-end align-items-center">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="mailto:">
                      cowiainfo@gmail.com
                    </a>
                  </li>
                  <li className="nav-item d-none d-lg-block">
                    <p className="nav-link text-white mb-0">|</p>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white"
                      href="https://www.linkedin.com/company/cowia/about/"
                      target="_blank"
                    >
                      <IconLinkedinSVG height="15" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

FooterComponent.propTypes = {
    t: PropTypes.func.isRequired,
  };
  
  export default translate(FooterComponent);