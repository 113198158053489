import React from "react";

function NotFoundPage() {
  return (
    <div>
      <h2> 404 </h2>
      <h4>No se ha encontrado la página seleccionada</h4>
    </div>
  );
}

export default NotFoundPage;
