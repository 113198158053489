import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBSUfyzmxob0KMC35DINDU-VFBH849AlZc",
  authDomain: "cowia-1af50.firebaseapp.com",
  projectId: "cowia-1af50",
  storageBucket: "cowia-1af50.appspot.com",
  messagingSenderId: "825565572618",
  appId: "1:825565572618:web:0b19aada54a077efaaf9f3"
};

const app = initializeApp(firebaseConfig);

export const firestoreDB = getFirestore(app)
export const auth = getAuth(app)