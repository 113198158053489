import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  translate,
} from 'react-switch-lang';


class LandingCalculatorComponent  extends Component {
    constructor(props) {
      super(props);
      this.state = {
        totalCowsAmoutValue: 0,
        totalMilkPriceValue: 0,
      };
    }
  
    render() {
      const {t} = this.props;

      const grade1Cows = (this.state.totalCowsAmoutValue * 70) / 100;
      const grade2Cows = (this.state.totalCowsAmoutValue * 17) / 100;
      const grade3Cows = (this.state.totalCowsAmoutValue * 11) / 100;
      const grade4Cows = (this.state.totalCowsAmoutValue * 1.5) / 100;
      const grade5Cows = (this.state.totalCowsAmoutValue * 0.5) / 100;
      const idividualDailyMilkAverage = 35;
    
      const block1 =
        (grade1Cows /
          (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
        1;
        const block2 =
        (grade2Cows /
          (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
        1;
        const block3 =
        (grade3Cows /
          (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
        0.949;
        const block4 =
        (grade4Cows /
          (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
        0.832;
        const block5 =
        (grade5Cows /
          (grade1Cows + grade2Cows + grade3Cows + grade4Cows + grade5Cows)) *
        0.64;
    
        const individualMilkLostAverageResult = idividualDailyMilkAverage / (block1 + block2 + block3 + block4 + block5) - idividualDailyMilkAverage;
    
      const individualMilkLostAverage =
        Number.isNaN(individualMilkLostAverageResult) ||
        individualMilkLostAverageResult < 0
          ? 0
          : individualMilkLostAverageResult;
    
          const totalDailyMilkLostAverage = this.state.totalCowsAmoutValue * individualMilkLostAverage * this.state.totalMilkPriceValue;
    
      const totalAnualMilkLostAverage = 365 * totalDailyMilkLostAverage;

      return (
      <section className="calculadora my-5">
        <div className="container bg-lightgreen py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-6">
                  <h2 className="wow fadeInUp">
                  {t('landing.calculator.title')}
                  </h2>
                  <p className="subtitle wow fadeInUp">
                    {t('landing.calculator.subtitle')}
                  </p>
                </div>
                <div className="col-12 col-lg-5">
                  <form>
                    <div className="mb-3">
                      <label for="number-cows" className="form-label">
                        {t('landing.calculator.cattleAmountTitle')}
  
                      </label>
                      <input
                        value={this.state.totalCowsAmoutValue}
                        type="number"
                        pattern="[0-9]*"
                        className="form-control"
                        id="number-cows"
                        onChange={(event) => {
                          const amount = event.target.value;
                          if (event.target.validity.valid) {
                            this.setState({totalCowsAmoutValue: amount});
                          }
                        }}
                        placeholder="0"
                      />
                    </div>
                    <label for="price-milk" className="form-label">
                    {t('landing.calculator.milkCostTitle')}
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        value={this.state.totalMilkPriceValue}
                        id="milkPrice"
                        onChange={(event) => {
                          const price = event.target.value;
                          if (!price || price.match(/^\d{1,}(\.\d{0,4})?$/)) {
                            this.setState({totalMilkPriceValue: price});
                          }
                        }}
                        type="number"
                        className="form-control"
                        aria-label="Precio por litro de leche:"
                        placeholder="0.0"
                      />
                    </div>
                  </form>
                  <p>
                  {t('landing.calculator.subtitleExplanation')}
                  </p>
                  <div className="bg-white p-3">
                    <p>
                  {t('landing.calculator.dailyMilkLossTitle')} 
                      <strong>${totalDailyMilkLostAverage.toFixed(2)}</strong>
                    </p>
                    <p className="mb-0">
                    {t('landing.calculator.annualMilkLossTitle')}
                      <strong>${totalAnualMilkLostAverage.toFixed(2)}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
   }
  }
  
  LandingCalculatorComponent.propTypes = {
    t: PropTypes.func.isRequired,
  };
  
  export default translate(LandingCalculatorComponent);