import React, { useState, useRef, Component } from "react";
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../js/script.js";
import { ReactComponent as ArrowDownSVG } from "../img/noun-arrow-arrows-down-arrow-1807797.svg";
import { ReactComponent as IconTimeSVG } from "../img/icon-time.svg";
import { ReactComponent as IconCowSVG } from "../img/icon-cow.svg";
import { ReactComponent as IconMoneySVG } from "../img/icon-money.svg";
import { ReactComponent as LogoORTCIESVG } from "../img/logo-ort-cie.svg";
import { ReactComponent as LogoPWCSVG } from "../img/logo-pwc.svg";
import { ReactComponent as IconRRHHSVG } from "../img/icon-rrhh.svg";
import LandingNavbarComponent from "./LandingNavbar.js";
import LandingCalculatorComponent from "./landingCalculator.js";
import LandingContactFormComponent from "./LandingContactForm";
import FooterComponent from "./FooterComponent";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import SolutionPNG from "../img/solution.png";
import WOW from "wowjs";

class LandingPage extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    const { t } = this.props;
    return (
      <html>
        <head>
          <title>Cow IA</title>
          <meta charSet="utf-8" />
          <meta name="keywords" content="" />
          <meta name="description" content="" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link rel="icon" type="image/png" href="assets/img/favicon.png" />
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css"
          ></link>
          <link rel="stylesheet" href="assets/css/style.css" />
          <script
            src="https://kit.fontawesome.com/728c8d7a81.js"
            crossOrigin="anonymous"
            defer
          ></script>
        </head>
        <body>
          <LandingNavbarComponent />

          <section className="main-banner d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <p className="text-uppercase subtitle text-white wow fadeInDown">
                    Cow IA
                  </p>
                  <h1 className="wow fadeInDown">
                    {t("landing.header.title")}
                  </h1>
                  <p className="lead mb-5 text-white wow fadeInDown">
                    {t("landing.header.subtitle")}
                  </p>
                  <a href="#problema">
                    <ArrowDownSVG width="22" id="arrow" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div className="bg-lightgreen">
            <div className="container py-3 text-center">
              <div className="row justify-content-center justify-content-md-around align-items-center">
                <div className="col-12 col-md-7 col-lg-4">
                  <figure className="d-flex align-items-center justify-content-center mb-md-0 text-center">
                    <figcaption className="me-3">{t("landing.logossection.supportus")}</figcaption>
                    <LogoORTCIESVG height="55" />
                  </figure>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  <figure className="d-flex align-items-center justify-content-center  mb-0 mx-auto">
                    <figcaption className="me-3">{t("landing.logossection.pwc")}</figcaption>
                    <LogoPWCSVG height="55" />
                  </figure>
                </div>
              </div>
            </div>
          </div>

          <section className="container py-5" id="problema">
            <div className="row mt-5">
              <div className="col-12 col-lg-9">
                <h2 className="text-green wow fadeInUp">
                  {t("landing.problem.title")}
                </h2>
                <p className="subtitle wow fadeInUp">
                  {t("landing.problem.subtitle")}
                </p>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 my-3">
              <div className="col wow fadeInUp" data-wow-delay=".3s ">
                <div className="card h-100">
                  <div className="card-header">
                    <IconTimeSVG height="75" />
                  </div>
                  <div className="card-body">
                    <p>{t("landing.problem.section1")}</p>
                  </div>
                </div>
              </div>
              <div className="col wow fadeInUp" data-wow-delay=".4s ">
                <div className="card h-100">
                  <div className="card-header">
                    <IconMoneySVG height="75" />
                  </div>
                  <div className="card-body">
                    <p>{t("landing.problem.section2")}</p>
                  </div>
                </div>
              </div>
              <div className="col wow fadeInUp" data-wow-delay=".5s ">
                <div className="card h-100">
                  <div className="card-header">
                    <IconCowSVG height="75" />
                  </div>
                  <div className="card-body">
                    {t("landing.problem.section3")}
                  </div>
                </div>
              </div>
              <div className="col wow fadeInUp" data-wow-delay=".6s ">
                <div className="card h-100">
                  <div className="card-header">
                    <IconRRHHSVG height="75" />
                  </div>
                  <div className="card-body">
                    <p>{t("landing.problem.section4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="solucion-section my-lg-5" id="solucion">
            <div className="container py-3 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-7 col-xl-5 offset-xl-4 bg-darkgreen py-4 p-md-5">
                  <h2 className="text-white wow fadeInUp">
                    {t("landing.solution.title")}
                  </h2>
                  <p className="subtitle text-white wow fadeInUp">
                    {t("landing.solution.subtitle")}
                  </p>
                  <p className="text-white text-bigger wow fadeInUp">
                    {t("landing.solution.subtitle2")}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9 text-center">
                <img
                  src={SolutionPNG}
                  alt="solucion a problema de patas de ganado vacuno con IA"
                  className="img-fluid d-lg-none mb-5"
                />
                <h3 className="wow fadeInUp">
                  {t("landing.solution.subtitle3")}
                </h3>
              </div>
            </div>
          </div>

          <div className="my-lg-5 py-3 py-lg-5" id="calculadora"></div>

          <LandingCalculatorComponent />

          <LandingContactFormComponent />

          <FooterComponent />

          <script src="../js/wow.min.js "></script>
          <script src="../js/jquery-3.6.0.min.js "></script>
          <script src="../js/bootstrap.bundle.min.js "></script>
          <script src="../js/script.js"></script>
        </body>
      </html>
    );
  }
}

LandingPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(LandingPage);
