import React, { Component } from "react";
import { auth, firestoreDB } from "../firebase-config";
import GradeSummaryChart from "../dashboard/GradeSummaryChart";
import ProgressChart from "../dashboard/ProgressChart";
import TableComponent from "../dashboard/TableComponent";
import Calculator from "../dashboard/CalculatorComponent";
import NavBar from "./DashboardNavBar";
import {
  ClientRegistersServiceURL,
  CowRegistersServiceURL,
} from "../js/constants";
import "../css/style-dashboard.css";
import "../js/script.js";
import PropTypes from 'prop-types';
import {
  translate,
} from 'react-switch-lang';

import { Timestamp, collection, query, where, getDocs } from "firebase/firestore";


class DashboardPage extends Component {
  selectedPeriodFilter = "0";
  chartData = [];
  constructor(props) {
    super(props);
    this.state = {
      items: undefined,
      userData: auth.currentUser?.email,
    };
  }

  componentDidMount() {
    this.onTodayFilterClick();
  }

  getUserDataFromServer = async (dateFrom, dateTo) => {
    this.chartData = [];
    const clientId = auth.currentUser?.uid;
    dateFrom.setHours(0, 0, 0, 0);
    dateTo.setHours(23, 59, 0, 0);
    
    const q = query(collection(firestoreDB, 'records'), 
    where('createdAt', '>=', dateFrom),
    where('createdAt', '<=', dateTo),
    where('clientId', '==', clientId));
    const docs = await getDocs(q);
    var grade1Count = 0;
    var grade2Count = 0;
    var grade3Count = 0;
    var grade4Count = 0;
    var grade5Count = 0;
    
    docs.forEach((doc) => {
      var data = doc.data();
      grade1Count += !isNaN(data.grade1) ? data.grade1 : 0;
      grade2Count += !isNaN(data.grade2) ? data.grade2 : 0;
      grade3Count += !isNaN(data.grade3) ? data.grade3 : 0;
      grade4Count += !isNaN(data.grade4) ? data.grade4 : 0;
      grade5Count += !isNaN(data.grade5) ? data.grade5 : 0;
      this.chartData.push({
        grade1: grade1Count,
        grade2: grade2Count,
        grade3: grade3Count,
        grade4: grade4Count,
        grade5: grade5Count,
        createdAt: data.createdAt
      })
    });

    this.setState({
      items: {
        grade1: grade1Count,
        grade2: grade2Count,
        grade3: grade3Count,
        grade4: grade4Count,
        grade5: grade5Count,
      },
      filterPeriod: [dateFrom, dateTo],
    });
  };

  onTodayFilterClick = () => {
    this.selectedPeriodFilter = "0";
    var dateFrom = new Date();
    var dateTo = new Date();
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  onYesterdayFilterClick = () => {
    this.selectedPeriodFilter = "1";
    var dateFrom = new Date();
    var dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 1)
    dateTo.setDate(dateTo.getDate() - 1)
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  onLast7DaysFilterClick = () => {
    this.selectedPeriodFilter = "7";
    var dateFrom = new Date();
    var dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7)
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  onLast15DaysFilterClick = () => {
    this.selectedPeriodFilter = "15";
    var dateFrom = new Date();
    var dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 15)
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  onLast30DaysFilterClick = () => {
    this.selectedPeriodFilter = "30";
    var dateFrom = new Date();
    var dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 30)
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  onLast90DaysFilterClick = () => {
    this.selectedPeriodFilter = "90";
    var dateFrom = new Date();
    var dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - 90)
    this.getUserDataFromServer(dateFrom, dateTo);
  };

  render() {
    const {t} = this.props;
    return this.state.items !== undefined ? (
      <div className="main-bg">
        <NavBar dataFromParent={auth.currentUser?.email} />
        <section className="container d-flex justify-content-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <ul class="nav nav-pills justify-content-left justify-content-md-start">
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "0"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onTodayFilterClick}
                  >
                      {t('dashboard.summary.today')}
                  </button>
                </li>
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "1"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onYesterdayFilterClick}
                  >
                      {t('dashboard.summary.yesterday')}
                  </button>
                </li>
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "7"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onLast7DaysFilterClick}
                  >
                      {t('dashboard.summary.last7days')}
                  </button>
                </li>
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "15"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onLast15DaysFilterClick}
                  >
                      {t('dashboard.summary.last15days')}
                  </button>
                </li>
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "30"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onLast30DaysFilterClick}
                  >
                      {t('dashboard.summary.last30days')}
                  </button>
                </li>
                <li class="nav-item mb-3 mb-md-4">
                  <button
                    class={
                      this.selectedPeriodFilter == "90"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    onClick={this.onLast90DaysFilterClick}
                  >
                      {t('dashboard.summary.last90days')}
                  </button>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                <h4 className="mb-3">
                  {t('dashboard.summary.title')}
                  </h4>
                <div className="row row-cols-2 row-cols-md-4 row-cols-xl-6 g-4 text-center ">
                  <div className="col-6 col-md-4 col-xl-2 ">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade1 +
                            this.state.items.grade2 +
                            this.state.items.grade3 +
                            this.state.items.grade4 +
                            this.state.items.grade5}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.totalRecords')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade1}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.grade1')}
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade2}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.grade2')}
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade3}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.grade3')}
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade4}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.grade4')}
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="card d-flex align-items-center justify-content-center h-100">
                      <div className="card-body">
                        <strong className="summary-value">
                          {this.state.items.grade5}
                        </strong>
                        <p className="mb-0">
                        {t('dashboard.summary.grade5')}
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-xl-3 g-4 mt-2 mt-md-0">
                  <div className="col-12 col-md-6 col-xl-2">
                    <div className="card p-4 h-100">
                      <h5 className="mb-4">
                      {t('dashboard.summary.calculator')}
                        </h5>
                      <Calculator dataItemsFromParent={this.state.items} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div className="card p-4 h-100 ">
                      <h5 className="mb-4">
                      {t('dashboard.summary.sample')}
                        </h5>
                      <GradeSummaryChart dataFromParent={this.state.items} />
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card p-4 h-100 ">
                      <ProgressChart
                        dataItemsFromParent={this.chartData}
                        dataFilterPeriodFromParent={this.state.filterPeriod}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
            </div>
          </div>
        </section>
      </div>
    ) : (
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(DashboardPage);