import React, { Component } from "react";

class TableComponent extends Component {
  state = { items: [] };

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.dataItemsFromParent,
    });
  }

  componentDidMount() {
    this.setState({
      items: this.props.dataItemsFromParent,
    });
  }
  render() {
    return (
      <div>
        {this.state.items.map((summary) => (
          <div className="card p-4 my-4">
            <div className="row">
              <div className="col-12 col-lg-5  mt-2">
                <h4>Datos cliente</h4>
                <p>
                  <b>Empresa:</b> <em>{summary.name}</em>
                </p>
                <p>
                  <b>Email:</b> <em>{summary.email}</em>
                </p>
                <p>
                  <b>Dirección:</b> <em>{summary.address}</em>
                </p>
                <p>
                  <b>Teléfono:</b> <em>{summary.phone}</em>
                </p>
                <p>
                  <b>Fecha ingreso:</b>
                  {new Date(summary.createdAt).getDate() +
                    "/" +
                    (new Date(summary.createdAt).getMonth() + 1) +
                    "/" +
                    new Date(summary.createdAt).getFullYear()}
                </p>
              </div>
              <div className="col-12 col-lg-7 mt-2">
                <h4>Registros</h4>
                <div className="row my-4">
                  <div className="col-6 col-md-4 col-xl-4 cell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.totalCows}
                      </strong>
                      <p className="mb-0">Total Vacas</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-4 cell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.totalRecords}
                      </strong>
                      <p className="mb-0">Total Lecturas</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4 col-xl-2 subcell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.grade1}
                      </strong>
                      <p className="mb-0">Grado 1</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 subcell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.grade2}
                      </strong>
                      <p className="mb-0">Grado 2</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 subcell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.grade3}
                      </strong>
                      <p className="mb-0">Grado 3</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 subcell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.grade4}
                      </strong>
                      <p className="mb-0">Grado 4</p>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2 subcell-summary">
                    <div className="p-3">
                      <strong className="summary-value">
                        {summary.grade5}
                      </strong>
                      <p className="mb-0">Grado 5</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default TableComponent;
