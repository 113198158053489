import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import debounce from "lodash.debounce";
import { translate, getLanguage } from "react-switch-lang";
import PropTypes from "prop-types";

class TableComponent extends Component {
  MAX_PAGING_SIZE = 100;

  state = {
    items: [],
    filterItems: [],
    gradeFilter: "",
    cowIdFilter: "",
    currentPage: 1,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.dataItemsFromParent,
      filterItems: this.getAllItems(nextProps.dataItemsFromParent),
    });
  }

  componentDidMount() {
    this.setState({
      items: this.props.dataItemsFromParent,
      filterItems: this.getAllItems(this.props.dataItemsFromParent),
    });
  }

  onPagingChange = (newPage) => {
    if (newPage < 1) {
      this.setState({
        currentPage: 1,
      });
    } else if (
      newPage > Math.ceil(this.state.filterItems.length / this.MAX_PAGING_SIZE)
    ) {
      this.setState({
        currentPage: Math.ceil(
          this.state.filterItems.length / this.MAX_PAGING_SIZE
        ),
      });
    } else {
      this.setState({
        currentPage: newPage,
      });
    }
  };

  onGradeFilterChange = (filterValue) => {
    if (filterValue.target !== undefined) {
      this.setState({
        gradeFilter: filterValue.target.value,
        currentPage: 1,
        filterItems: this.filterItemsWithGrade(
          this.state.items,
          filterValue.target.value
        ),
      });
    }
  };

  onCowIdFilterChange = debounce((filterValue) => {
    if (filterValue.target !== undefined) {
      this.setState({
        cowIdFilter: filterValue.target.value.trim(),
        currentPage: 1,
        filterItems: this.filterItemsWithCowId(
          this.state.items,
          filterValue.target.value.trim()
        ),
      });
    }
  }, 500);

  getAllItems(itemsFromParent) {
    var grades = [];
    grades = grades.concat(
      itemsFromParent["grade1"],
      itemsFromParent["grade2"],
      itemsFromParent["grade3"],
      itemsFromParent["grade4"],
      itemsFromParent["grade5"]
    );
    return grades;
  }

  filterItemsWithGrade(itemsFromParent, gradeFilterValue) {
    var grades = [];
    switch (gradeFilterValue) {
      case "grade1":
      case "grade2":
      case "grade3":
      case "grade4":
      case "grade5":
        grades = itemsFromParent[gradeFilterValue];
        break;
      default:
        grades = this.getAllItems(itemsFromParent);
    }
    if (this.state.cowIdFilter !== "") {
      grades = grades.filter((register) =>
        register.cowId.includes(this.state.cowIdFilter)
      );
    }
    return grades;
  }

  filterItemsWithCowId(itemsFromParent, cowIdFilterValue) {
    var grades = [];
    switch (this.state.gradeFilterValue) {
      case "grade1":
      case "grade2":
      case "grade3":
      case "grade4":
      case "grade5":
        grades = itemsFromParent[this.state.gradeFilterValue];
        break;
      default:
        grades = this.getAllItems(itemsFromParent);
    }
    if (cowIdFilterValue !== "") {
      grades = grades.filter((register) =>
        register.cowId.includes(cowIdFilterValue)
      );
    }
    return grades;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row justify-content-between mb-3">
          <div className="col-12 col-md-8 col-xl-6">
            <div className="row">
              <div className="col-12 col-md-6">
                <select
                  onChange={this.onGradeFilterChange}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="">{t("dashboard.detailTable.all")}</option>
                  <option value="grade1">
                    {t("dashboard.detailTable.grade1")}
                  </option>
                  <option value="grade2">
                    {t("dashboard.detailTable.grade2")}
                  </option>
                  <option value="grade3">
                    {t("dashboard.detailTable.grade3")}
                  </option>
                  <option value="grade4">
                    {t("dashboard.detailTable.grade4")}
                  </option>
                  <option value="grade5">
                    {t("dashboard.detailTable.grade5")}
                  </option>
                </select>
              </div>
              <div className="col-12 col-md-6">
                <input
                  placeholder={t("dashboard.detailTable.filterById")}
                  type="text"
                  onChange={this.onCowIdFilterChange}
                  className="roundedInput form-control mt-3 mt-md-0"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-2 text-end ">
            <ReactHTMLTableToExcel
              filename="CowIA-Records"
              className="btn btn-info w-100 mt-3 mt-md-0"
              table="dataTable"
              sheet="Sheet"
              buttonText={t("dashboard.detailTable.export")}
            />
          </div>
        </div>

        {this.getPagerComponent()}

        <table id="dataTable" class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{t("dashboard.detailTable.identifier")}</th>
              <th scope="col">{t("dashboard.detailTable.locomotorScore")}</th>
              <th scope="col">{t("dashboard.detailTable.recordDate")}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.filterItems.map((item, index) =>
              this.buildRowItem(item, index)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  getPagerComponent() {
    const { t } = this.props;
    if (this.state.filterItems.length > 0) {
      return (
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item">
              <button
                class="page-link"
                onClick={() => this.onPagingChange(this.state.currentPage - 1)}
              >
                {t("dashboard.detailTable.previous")}
              </button>
            </li>
            <li class="page-item active">
              <a class="page-link">{this.state.currentPage}</a>
            </li>
            <li class="page-item">
              <button
                class="page-link"
                onClick={() => this.onPagingChange(this.state.currentPage + 1)}
              >
                {t("dashboard.detailTable.next")}
              </button>
            </li>
          </ul>
        </nav>
      );
    }
  }

  buildRowItem(item, index) {
    if (
      index >= this.MAX_PAGING_SIZE * (this.state.currentPage - 1) &&
      index <= this.MAX_PAGING_SIZE * this.state.currentPage
    ) {
      return (
        <tr>
          <td scope="row">{item.cowId}</td>
          <td>{item.grade}</td>
          <td>
            {new Date(item.createdAt).getDate() +
              " " +
              new Date(item.createdAt).toLocaleString(
                getLanguage() == "sp" ? "es-ES" : "en-EN",
                {
                  month: "short",
                }
              ) +
              " " +
              new Date(item.createdAt).getUTCFullYear()}
          </td>
        </tr>
      );
    }
  }
}

TableComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(TableComponent);
