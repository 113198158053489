import React, { Component } from "react";
import TableComponent from "./TableComponent";
import {
  ClientsSummaryServiceURL,
  ClientRegistersServiceURL,
} from "../js/constants";
import "../js/script.js";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import NavBar from "../admin/ClientSummaryNavBar";
import { auth } from "../firebase-config";
import "../css/style-dashboard.css";

class DashboardPage extends Component {
  state = { date: new Date(), clientNameFilter: "" };

  componentDidMount() {
    this.getUserDataFromServer();
    this.getClientsSummaryDataFromServer(this.state.date);
  }

  getUserDataFromServer = () => {
    const emailUser = auth.currentUser?.email;
    const url = ClientRegistersServiceURL + emailUser;

    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            userData: result,
          });
        },
        (error) => {
          console.error("ERROR:", error);
        }
      );
  };

  getClientsSummaryDataFromServer = (selecteDate) => {
    var selectedDay = selecteDate;
    var startDay = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth(),
      1
    );
    startDay.setHours(0, 0, 0, 0);
    var lastDayOfMonth = new Date(
      selectedDay.getFullYear(),
      selectedDay.getMonth() + 1,
      0
    );
    lastDayOfMonth.setHours(23, 59, 0, 0);
    const fromTime = startDay.getTime();
    const toTime = lastDayOfMonth.getTime();
    const url =
      ClientsSummaryServiceURL +
      "?" +
      "fromTime=" +
      fromTime +
      "&toTime=" +
      toTime;

    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            items: result,
            date: selecteDate,
          });
        },
        (error) => {
          console.error("ERROR:", error);
        }
      );
  };

  onClientNameFilterChange = (newValue) => {
    this.setState({ clientNameFilter: newValue.target.value });
  };

  render() {
    const Picker = React.forwardRef(({ value, onClick }, ref) => (
      <button className="btn btn-primary" onClick={onClick} ref={ref}>
        {value}
      </button>
    ));

    return this.state.items !== undefined ? (
      <div className="main-bg h-100">
        <NavBar dataFromParent={auth.currentUser?.displayName} />
        <section className="container d-flex justify-content-center">
          <div className="row justify-content-center  mt-5">
            <div className="row mt-5">
              <div className="col-8 col-lg-11">
                <h4 className="mb-0">Resumen del período</h4>
              </div>
              <div className="col-4 col-lg-1 text-end">
                <DatePicker
                  selected={this.state.date}
                  locale="es"
                  onChange={(newDate) => {
                    this.getClientsSummaryDataFromServer(newDate);
                  }}
                  customInput={<Picker />}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-outline col-12 col-lg-3 mt-3">
                <input
                  className="form-control"
                  placeholder="Filtro por nombre"
                  type="text"
                  onChange={this.onClientNameFilterChange}
                />
              </div>
              <div className="col-lg-6" />
            </div>
            <div className="col-12 mt-2">
              <TableComponent
                dataItemsFromParent={this.state.items.filter((item) =>
                  item.name
                    .toLowerCase()
                    .includes(this.state.clientNameFilter.toLowerCase())
                )}
              />
            </div>
          </div>
        </section>
      </div>
    ) : (
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
    );
  }
}

export default DashboardPage;
