import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  translate,
} from 'react-switch-lang';
import emailjs from "@emailjs/browser";


class LandingContactFormComponent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailValue: "",
            contactFormOK: false,
            contactFormERROR: false,
        };
      }
    
    form = React.createRef();

    render() {

    const {t} = this.props;
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
          .sendForm(
            "service_2tgah8h",
            "template_u7k3ty8",
            this.form.current,
            "rUN_f2vSeJCmVpSSE"
          )
          .then(
            (result) => {
              this.setState({contactFormOK: true});
              this.setState({contactFormERROR: false});
              console.log(result.text);
            },
            (error) => {
              this.setState({contactFormOK: false});
              this.setState({contactFormERROR: true});
              console.log(error.text);
            }
          );
      };

    return (
      <section className="container my-5 py-5" id="contacto">
        <form ref={this.form} onSubmit={sendEmail}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7 text-center">
              <h2 className="wow fadeInUp">{t('landing.contact.title')}</h2>
              <p className="subtitle wow fadeInUp">
                {t('landing.contact.subtitle')}
              </p>
              <div className="row justify-content-center">
                <div class="col-12 col-lg-6 wow fadeInUp">
                  <div className="mb-3 text-start">
                    {this.state.contactFormOK ? (
                      <label className="form-label">
                                      {t('landing.contact.success')}
                      </label>
                    ) : (
                      <div>
                        <label for="mail" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control mb-3"
                          placeholder="mail@mail.com"
                          name="user_email"
                          onChange={(event) => {
                            this.setState({emailValue: event.target.value});
                          }}
                        />
                        {this.state.contactFormERROR ? (
                          <label className="form-label">
                                          {t('landing.contact.error')}
                          </label>
                        ) : null}
                        <button
                          type="submit"
                          className={
                            this.state.emailValue.length > 0
                              ? "btn btn-primary w-100"
                              : "btn btn-primary w-100 disabled"
                          }
                        >
                                        {t('landing.contact.send')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }
}


LandingContactFormComponent.propTypes = {
    t: PropTypes.func.isRequired,
  };
  
  export default translate(LandingContactFormComponent);