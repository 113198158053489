import React, { Component } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";
import { useNavigate } from "react-router-dom";

class NavBar extends Component {
  chartRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    this.setState({ userName: nextProps.dataFromParent.name });
  }

  componentDidMount() {
    if (this.props.dataFromParent != null) {
      this.setState({ userName: this.props.dataFromParent.name });
    }
  }

  logout = async () => {
    await signOut(auth);
    let navigate = useNavigate();
    navigate("/login");
  };

  render() {
    return (
      <nav class="navbar-expand-lg navbar-dark py-2 fixed-top" id="nav-dashboard">
        <div class="container d-flex justify-content-between  align-items-center px-lg-0">
          <div class="pl-md-0 col-6 px-sm-0 col-md-3 col-lg-2 navbar-header py-2 py-md-0">
            <img
              class="img-fluid"
              alt="logo"
              src={require("../img/cow-ia-color.png")}
              width="125"
            />
          </div>
          <div
            class="col-6 col-md-9 col-lg-6  align-items-md-center justify-content-end"
            id="cow-menu"
          >
            <ul class="navbar-nav flex-row text-center align-items-md-center justify-content-end ">
              <li class="nav-item px-3 d-none d-md-block">
                <img
                  src={require("../img/circle-user-solid.png")}
                  class="me-3"
                />
                {this.state != null ? this.state.userName : ""}
              </li>
              <li class="nav-item px-md-3">
                <button
                  class="btn btn-outline-success"
                  type="button"
                  onClick={() => this.logout()}
                >
                  <img
                    src={require("../img/arrow-right-from-bracket-solid.png")}
                    class="me-3"
                  />
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
