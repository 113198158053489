import React from "react";
import { ReactComponent as IconCOWWhiteSVG } from "../img/cow-ia-white.svg";
import {
  setLanguage,
  getLanguage,
  translate
} from 'react-switch-lang';
import LanguageSwitchComponent from "../utils/LanguageSwitch.js"

class LandingNavbarComponent extends React.Component {
  state = { active: true };

  handleSetLanguage = () => {
    if(getLanguage() == "sp"){
      setLanguage("en");
    }else{
      setLanguage("sp");
    }
  };

  handleScroll = (event) => {
    const { pageYOffset } = window;
    if (pageYOffset === 0) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {t} = this.props;
    return (
      <nav className="navbar-expand-lg fixed-top navbar-dark py-2">
        <div className="container d-flex justify-content-between justify-content-lg-start align-items-center px-lg-0">
          <div className="pl-md-0 col-6 px-sm-0 col-md-3 col-lg-2 navbar-header text-center text-lg-left d-flex d-lg-block justify-content-between py-2 py-md-0">
            <a className="navbar-brand py-0" href="/">
              {this.state.active ? (
                <IconCOWWhiteSVG width="125" />
              ) : (
                <img
                  alt="logo"
                  src={require("../img/cow-ia-color.png")}
                  width="125"
                />
              )}
            </a>
          </div>
          <div
            className="col-12 col-md-9 col-lg-6 navbar-collapse collapse align-items-lg-center "
            id="cow-menu"
          >
            <ul className="navbar-nav text-center align-items-lg-start justify-content-between ">
              <li className="nav-item px-3">
                <a className="nav-link" href="#problema">
                {t('landing.navbar.lamenessProblem')}
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="#solucion">
                {t('landing.navbar.ourSolution')}
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="#calculadora">
                {t('landing.navbar.calculateYourLoss')}
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="#contacto">
                {t('landing.navbar.contact')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 d-flex align-items-center justify-content-end pr-md-0">
            <LanguageSwitchComponent/>          
            <a className="btn btn-outline-primary" href="/login">
              {t('landing.navbar.login')}
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default translate(LandingNavbarComponent);
