import React from "react";
import {
  setLanguage,
  getLanguage,
  translate
} from 'react-switch-lang';
import SpainSVG from "../img/spain.png";
import USASVG from "../img/usa.png";

class LanguageSwitchComponent extends React.Component {

  handleSetLanguage = () => {
    if(getLanguage() == "sp"){
      localStorage.setItem("language", "en");
      setLanguage("en");
    }else{
      setLanguage("sp");
      localStorage.setItem("language", "sp")
    }
  };

  handleScroll = (event) => {
    const { pageYOffset } = window;
    if (pageYOffset === 0) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <a className="m-3" onClick={this.handleSetLanguage}>
            {(getLanguage() == "sp" ?
              <img src={SpainSVG} height="24"/> : <img src={USASVG} height="24"/>)}
    </a>
    );
  }
}

export default translate(LanguageSwitchComponent);
