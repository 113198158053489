import $ from 'jquery';
import { WOW } from 'wowjs';

$(document).ready(function() {
    new WOW().init();
});


$(function() {
    $(document).scroll(function() {
        var $nav = $(".fixed-top");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });
});


