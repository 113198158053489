import "./App.css";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import ClientsSummaryPage from "./admin/ClientsSummaryPage";
import LoginPage from "./login/loginpage";
import NotFoundPage from "./notfound/notfoundpage";
import LandingPage from "./landing/landingpage";
import ResetPasswordPage from "./login/resetpassword";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage
} from 'react-switch-lang';

import en from '../src/language/en.json';
import sp from '../src/language/sp.json';

import { auth } from "./firebase-config";

setTranslations({ en, sp });
setDefaultLanguage('en');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      initializing: true
    };
    this.setupLanguage()    
  }

  setupLanguage(){
    try{
      var localLanguage = localStorage.getItem("language");
      if(localLanguage){
        setDefaultLanguage(localLanguage)
        setLanguage(localLanguage)
      }
    }catch(ex){
      //Nothing
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          initializing: false
        });
      } else {
        this.setState({
          authenticated: false,
          initializing: false
        });
      }
    });
  }

  render() {

  if (this.state.initializing) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPassword" element={<ResetPasswordPage />} />
        <Route
          path="/dashboard"
          element={this.state.authenticated ? <DashboardPage /> : <LoginPage />}
        />
        <Route
          path="/clientsSummary"
          element={this.state.authenticated ? <ClientsSummaryPage /> : <LoginPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}
}

export default App;
