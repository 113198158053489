import React, { Component, createRef } from "react";
import Chart from "chart.js/auto";
import PropTypes from 'prop-types';
import {
  getLanguage,
  translate
} from 'react-switch-lang';

class ProgressChart extends Component {
  chartRef = createRef();
  chart = null;

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataItemsFromParent !== this.props.dataItemsFromParent || prevProps.dataFilterPeriodFromParent !== this.props.dataFilterPeriodFromParent) {
      this.renderChart();
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  renderChart() {
    const ctx = this.chartRef.current.getContext("2d");
    const { dataItemsFromParent, dataFilterPeriodFromParent } = this.props;

    const grade1 = dataItemsFromParent.map(item => ({ grade: item.grade1, createdAt: item.createdAt.toDate()}));
    const grade2 = dataItemsFromParent.map(item => ({ grade: item.grade2, createdAt: item.createdAt.toDate()}));
    const grade3 = dataItemsFromParent.map(item => ({ grade: item.grade3, createdAt: item.createdAt.toDate()}));
    const grade4 = dataItemsFromParent.map(item => ({ grade: item.grade4, createdAt: item.createdAt.toDate()}));
    const grade5 = dataItemsFromParent.map(item => ({ grade: item.grade5, createdAt: item.createdAt.toDate()}));

    const [dateFrom, dateTo] = dataFilterPeriodFromParent;

    const times = this.generateTimes(dateFrom, dateTo);

    const getTimesData = (grades) => {
      const countByTime = Array(times.length).fill(0);
    
      grades.forEach(gradeItem => {
        const gradeDate = gradeItem.createdAt;
    
        times.forEach((timeItem, index) => {
          const timeItemStart = new Date(timeItem);
          const timeItemEnd = new Date(timeItemStart.getTime() + 24 * 60 * 60 * 1000);
    
          if (gradeDate >= timeItemStart && gradeDate < timeItemEnd) {
            countByTime[index] += gradeItem.grade;
          }
        });
      });
    
      return countByTime;
    };

    const getGradeFilters = () => {
      const selectedFilter = this.state?.selectedFilter || "";

      switch (selectedFilter) {
        case "grade1":
          return [{
            data: getTimesData(grade1),
            label: "Grade 1",
            borderColor: "#00B3B7",
            backgroundColor: "#00B3B7",
            fill: false,
          }];
        case "grade2":
          return [{
            data: getTimesData(grade2),
            label: "Grade 2",
            borderColor: "#BFAC36",
            backgroundColor: "#BFAC36",
            fill: false,
          }];
        case "grade3":
          return [{
            data: getTimesData(grade3),
            label: "Grade 3",
            borderColor: "#DC8A51",
            backgroundColor: "#DC8A51",
            fill: false,
          }];
        case "grade4":
          return [{
            data: getTimesData(grade4),
            label: "Grade 4",
            borderColor: "#6F9E31",
            backgroundColor: "#6F9E31",
            fill: false,
          }];
        case "grade5":
          return [{
            data: getTimesData(grade5),
            label: "Grade 5",
            borderColor: "#8C3424",
            backgroundColor: "#8C3424",
            fill: false,
          }];
        default:
          return [
            {
              data: getTimesData(grade1),
              label: "Grade 1",
              borderColor: "#00B3B7",
              backgroundColor: "#00B3B7",
              fill: false,
            },
            {
              data: getTimesData(grade2),
              label: "Grade 2",
              borderColor: "#BFAC36",
              backgroundColor: "#BFAC36",
              fill: false,
            },
            {
              data: getTimesData(grade3),
              label: "Grade 3",
              borderColor: "#DC8A51",
              backgroundColor: "#DC8A51",
              fill: false,
            },
            {
              data: getTimesData(grade4),
              label: "Grade 4",
              borderColor: "#6F9E31",
              backgroundColor: "#6F9E31",
              fill: false,
            },
            {
              data: getTimesData(grade5),
              label: "Grade 5",
              borderColor: "#8C3424",
              backgroundColor: "#8C3424",
              fill: false,
            }
          ];
      }
    };

    this.destroyChart();

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: times.map(time => new Date(time).toLocaleString(getLanguage() === "sp" ? "es-ES" : "en-EN", { day: "numeric", month: "long" })),
        datasets: getGradeFilters(),
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
    });
  }

  generateTimes(dateFrom, dateTo) {
    const times = [];
    const currentDate = new Date(dateFrom.getTime());

    while (currentDate < dateTo) {
      times.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return times;
  }

  onGradeFilterChange = (filterValue) => {
    const selectedFilter = filterValue.target.value;
    this.setState({ selectedFilter }, this.renderChart);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="row justify-content-between">
        <div className="col-12 col-md-6">
          <h5 className="mb-4">{t('dashboard.progressChart.variations')}</h5>
        </div>
        <div className="text-end col-12 col-md-4">
          <select
            onChange={this.onGradeFilterChange}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">{t('dashboard.progressChart.all')}</option>
            <option value="grade1">{t('dashboard.progressChart.grade1')}</option>
            <option value="grade2">{t('dashboard.progressChart.grade2')}</option>
            <option value="grade3">{t('dashboard.progressChart.grade3')}</option>
            <option value="grade4">{t('dashboard.progressChart.grade4')}</option>
            <option value="grade5">{t('dashboard.progressChart.grade5')}</option>
          </select>
        </div>
        <canvas className="mt-3" ref={this.chartRef} />
      </div>
    );
  }
}

ProgressChart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ProgressChart);
