import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase-config";
import $ from "jquery";
import PropTypes from 'prop-types';
import {
  translate,
} from 'react-switch-lang';
import { ReactComponent as IconCOWWhiteSVG } from "../img/cow-ia-white.svg";
import LanguageSwitchComponent from "../utils/LanguageSwitch.js"

class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        emailValue: "",
        passwordValue: ""
    };
  }
  
  navigateToDashboard = () => {
    window.location.replace('/dashboard');
  }

  hideLoginError = async () => {
    $("#error-alert").hide();
  };

  showLoginError = async () => {
    $("#error-alert")
      .fadeTo(5000, 500)
      .slideUp(500, function () {
        $("#error-alert").slideUp(500);
      });
  };

  login = async () => {
    try {
      let result = await signInWithEmailAndPassword(
        auth,
        this.state.emailValue,
        this.state.passwordValue
      );
      if (result.user) {
        this.hideLoginError();
        this.navigateToDashboard();
      }
    } catch (error) {
      this.showLoginError();
      console.log(error.message);
    }
  };

  render() {
    const {t} = this.props;
    return (
    <html>
      <head>
        <title>Cow IA</title>
        <meta charSet="utf-8" />
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="icon" type="image/png" href="assets/img/favicon.png" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <script
          src="https://kit.fontawesome.com/728c8d7a81.js"
          crossOrigin="anonymous"
          defer
        ></script>
      </head>
      <body>
        <nav className="navbar-expand-lg fixed-top navbar-dark py-2">
          <div className="container d-flex justify-content-between  align-items-center px-lg-0">
            <div className="pl-md-0 col-6 px-sm-0 col-md-3 col-lg-2 navbar-header text-center text-lg-left d-flex d-lg-block justify-content-between py-2 py-md-0">
              <a className="navbar-brand py-0" href="/">
                <IconCOWWhiteSVG width="125" />
              </a>
            </div>
            <div className="col-6 text-end">
            <LanguageSwitchComponent/>

            </div>
          </div>
        </nav>
        <section className="login-banner d-flex align-items-center">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div className="alert alert-danger collapse" id="error-alert">
                  {t('login.wrongEmailAndPassword')}

                </div>
                <div className="card shadow-2-strong">
                  <div className="card-body p-5 text-center">
                    <h2 className="mb-5">{t('login.title')}</h2>

                    <div className="form-outline mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={(event) => {
                          this.setState({emailValue: event.target.value});
                        }}
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t('login.password')}
                        onChange={(event) => {
                          this.setState({passwordValue: event.target.value});
                        }}
                      />
                    </div>

                    <div className="form-check ps-0 d-flex justify-content-center mb-4">
                      <a className = "text-link" href="/resetpassword">
                      {t('login.forgetPassword')}
                      </a>
                    </div>

                    <button
                      className={
                        this.state.emailValue.length > 0 && this.state.passwordValue.length > 0
                          ? "btn btn-primary w-100"
                          : "btn btn-primary w-100 disabled"
                      }
                      type="button"
                      onClick={this.login}
                    >
                      {t('login.login')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </html>
  );
}
}

LoginPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(LoginPage);